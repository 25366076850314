.portfolio__item {
    width: 33.33333%;
    max-width: 650px;
    padding: 0 2px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        width: 50%;
    }

    a {
        display: block;
    }
}
