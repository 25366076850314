.portfolio-details {
    position: relative;

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100vh;
    }

    &__item {
        flex-grow: 1;
        padding: 24px;
        text-align: center;
    }

    &__loading {
        @include fadeout(200ms);
    }

    &__img {
        @include fadein(200ms);
    }

    .close-btn {
        position: absolute;
        top: 25px;
        left: 25px;

        @media (max-width: 767px) {
            top: 12px;
            left: 12px;
        }
    }

    .arrow.decrement {
        margin-left: 75px;

        @media (max-width: 767px) {
            margin-left: 25px;
        }
    }

    .arrow.increment {
        margin-right: 75px;
        transform: rotate(180deg);

        @media (max-width: 767px) {
            margin-right: 25px;
        }
    }

    .link {
        text-decoration: none;
        opacity: 1;
        transition: all 200ms ease-in-out;

        &--disabled {
            pointer-events: none;
            opacity: 0.2;
        }
    }

    .mobile-landscape-size {
        @media (max-width: 767px) and (orientation: landscape) {
            width: auto;
            height: auto;
            max-height: calc(100vh - 48px);
        }
    }
}
