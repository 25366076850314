.portfolio {
    &__header {
        position: relative;
        padding: 80px 0;
        text-align: center;

        .back-btn {
            position: absolute;
            top: 115px;
            left: 40px;

            @media (max-width: 1365px) {
                top: 25px;
                left: 25px;
            }

            @media (max-width: 767px) {
                top: 12px;
                left: 12px;
            }
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 2px;
        margin-bottom: 100px;
    }
}
