.home {
    position: relative;
    overflow: hidden;

    &__logo-container {
        position: relative;
        margin-top: 290px;
        padding: 155px 0;
        text-align: center;
        background-color: #e0f9ea;
        z-index: 5;
        border-top: 16px solid #ffffff;
        border-bottom: 16px solid #ffffff;
        height: 560px;

        @media (max-width: 1023px) {
            margin-top: 100px;
            padding: 100px 15px;
            height: auto;
        }
    }

    &__logo {
        font-size: 150px;
        color: #ff9290;

        span {
            display: block;
        }

        @media (max-width: 1023px) {
            font-size: 100px;
        }

        @media (max-width: 767px) {
            font-size: 60px;
        }
    }

    &__design {
        margin-top: -90px;
        width: 100%;
        max-width: 481px;

        @media (max-width: 1023px) {
            margin-top: -55px;
            max-width: 300px;
        }

        @media (max-width: 767px) {
            margin-top: -30px;
            max-width: 180px;
        }
    }

    &__paths {
        position: relative;
        margin: 405px auto 250px;
        display: flex;
        width: calc(100% - 430px);
        max-width: 1455px;
        z-index: 5;

        @media (max-width: 1023px) {
            flex-direction: column;
            width: 100%;
            margin: 200px auto 150px;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin: 200px auto 150px;
        }
    }

    &__path {
        width: 50%;
        padding: 300px 0;
        @include font-balboa();
        font-size: 70px;
        text-align: center;

        @media (max-width: 1450px) {
            padding: 200px 0;
        }

        @media (max-width: 1200px) {
            width: 75%;
            margin: 0 auto;
            padding: 150px 0;
            font-size: 50px;
        }

        @media (max-width: 767px) {
            padding: 100px 0;
            font-size: 40px;
        }

        a {
            text-decoration: none;
        }

        &.about-me {
            background-color: #e0f9ea;

            a {
                color: #00544e;
                @include textshadow-green();
            }
        }

        &.portfolio {
            background-color: #ffe8e8;

            a {
                color: #ff9290;
                @include textshadow-pink();
            }
        }
    }
}
