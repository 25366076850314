// Fonts
// ========================
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap');

@font-face {
    font-family: 'Balboa Plus Fill';
    src: url('../assets/fonts/balboa-plus-fill.woff') format('woff'),
        url('../assets/fonts/balboa-plus-fill.woff2') format('woff2');
}

// Mixins
// ========================
@mixin textshadow-pink() {
    text-shadow: 2px 2px 2px rgba(235, 105, 170, 1);
}

@mixin textshadow-green() {
    text-shadow: 2px 2px 2px rgba(52, 133, 11, 1);
}

@mixin font-balboa() {
    font-family: 'Balboa Plus Fill', Arial, sans-serif;
}

@mixin fadein($time) {
    -webkit-animation: fadein $time; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $time; /* Firefox < 16 */
    -ms-animation: fadein $time; /* Internet Explorer */
    -o-animation: fadein $time; /* Opera < 12.1 */
    animation: fadein $time;
}

@mixin fadeout($time) {
    -webkit-animation: fadeout $time; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout $time; /* Firefox < 16 */
    -ms-animation: fadeout $time; /* Internet Explorer */
    -o-animation: fadeout $time; /* Opera < 12.1 */
    animation: fadeout $time;
}

// Keyframes
// ========================
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// General
// ========================
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-balboa();
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    @include textshadow-pink();
}

p,
span {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 16px;
    color: #fff;
}

body {
    background-color: #ffc8c7;
}

.img {
    width: 100%;
}

.page-title {
    font-size: 100px;

    @media (max-width: 1023px) {
        font-size: 80px;
    }

    @media (max-width: 767px) {
        font-size: 60px;
    }
}

.nav-icon {
    @media (max-width: 1365px) {
        max-width: 40px;
    }

    @media (max-width: 767px) {
        max-width: 20px;
    }
}

.leaves {
    position: absolute;
    top: -200px;

    @media (max-width: 1023px) {
        top: -500px;
    }

    &.left {
        left: -310px;

        @media (max-width: 1023px) {
            left: -420px;
        }

        @media (max-width: 767px) {
            left: -470px;
        }
    }

    &.right {
        right: -310px;

        @media (max-width: 1023px) {
            right: -420px;
        }

        @media (max-width: 767px) {
            right: -470px;
        }
    }
}

.text-link {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #ff918f;
}

// Imports
// ========================
@import 'partials/about';
@import 'partials/four-oh-four';
@import 'partials/home';
@import 'partials/portfolio-details';
@import 'partials/portfolio-item';
@import 'partials/portfolio';
