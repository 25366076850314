.about {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    min-height: 100vh;

    .leaves.left,
    .leaves.right {
        top: -1000px;
    }

    &__container {
        position: relative;
        margin: auto;
        display: flex;
        width: calc(100% - 430px);
        max-width: 1455px;
        z-index: 5;

        @media (max-width: 1200px) {
            flex-direction: column;
            text-align: center;
            margin: 100px auto;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 20px;
        }
    }

    &__profile-pic {
        margin-right: 60px;
        max-width: 359px;
        max-height: 538px;

        @media (max-width: 1200px) {
            margin: 0 auto;
        }

        @media (max-width: 767px) {
            max-width: 240px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        @media (max-width: 1023px) {
            padding-top: 30px;
        }

        h1 {
            color: #ff918f;

            @media (max-width: 1023px) {
                margin-bottom: 18px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 18px;
        }

        a {
            color: #ff918f;
        }
    }

    &__social {
        display: flex;
        padding: 18px 0;

        @media (max-width: 1200px) {
            justify-content: center;
        }
    }

    &__social-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;

        @media (max-width: 1200px) {
            margin-left: 8px;
            margin-right: 8px;
        }

        svg {
            fill: #ff918f;
        }
    }
}
